<template>
  <div class="page-main-bg" id="propertiesPage">
    <v-container
      class="main-container position-relative"
      :class="{ 'list-view': !gridView, 'grid-view': gridView }"
    >
      <!------------------------------------------->
      <!-------------Toolbar----------------------->
      <!-------------------------------------------->
      <tool-bar
        v-bind="toolbarProps"
        @toggleCards="toggleShowStats"
        @setDate="setDate"
      >
        <slot />
      </tool-bar>

      <!------------------------------------------->
      <!-------------realEstatStat----------------------->
      <!-------------------------------------------->
      <v-slide-y-transition>
        <realEstatStat
          v-if="$hasPermission('statistics', 'statistics', 'list')"
          v-show="cardsInfoState"
          statesType="realestates"
          :datesQuery="datesQuery"
        />
      </v-slide-y-transition>

      <!-------card Exceed Limit-------->
      <cardExceedLimit
        v-if="exceededAddingProperty"
        :title="exceedTitle"
        :subTitle="exceedSubTitle"
      />

      <!------------------------------------------->
      <!-------------Property filters-------------->
      <!------------------------------------------->

      <PropertyFilter
        :statusRestore="statusRestore"
        :gridView="gridView"
        :showHasContract="showHasContractsOnly"
        :availableFilters="availableFilters"
        @filterStatusChanged="changeFilterStatus"
        @filterTypesChanged="changeFilterTypes"
        @gridViewChanged="changeGridView"
        @dataFilterChanged="changeTableHeaders"
        @showHasAContract="showHasAContract"
        @search="searchRealEstates"
      >
        <!------------------------------------>
        <!----MultipleSelectionsForDelete----->
        <!------------------------------------>

        <MultipleSelectionsForDelete
          v-bind="multipleSelectionsData"
          :showRestoreDeleted="false"
          @changeSelect="selectAllChanged"
          @switched="switched"
        />
      </PropertyFilter>

      <!------------------------------------------->
      <!-------------AddPropertyModal--------------->
      <!-------------------------------------------->

      <unit-modal
        v-if="dialogState"
        :dialogState="dialogState"
        @closed-property-dialog="closeAddDialog"
      />

      <!------------------------------------------->
      <!-------------AddPropertyModal--------------->
      <!-------------------------------------------->

      <v-dialog persistent v-model="addState" max-width="328px">
        <v-card class="add-new-property pa-5">
          <div class="d-flex justify-space-between p a-0">
            <div></div>
            <v-btn
              @click.prevent=";(addState = false), getProperties()"
              class="ma-0"
              color="grey"
              fab
              text
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>

          <div class="d-flex flex-column align-center">
            <img :src="addPropCardIcon" alt="" class="mb-6" loading="lazy" />
            <h4 class="ma-0">{{ addCard.title }}</h4>
            <h6 class="grey--text font-weight-light ma-0 mt-3">
              {{ addCard.subTitle }}
            </h6>
            <v-btn
              @click="setModal"
              block
              class="add-new-property-arrow rounded-15 font-14 mt-5 h6 pb-2"
              large
              outlined
              rounded
            >
              <span class="mx-2 mt-1">
                {{ addCard.unitStr }}
              </span>
            </v-btn>
          </div>
        </v-card>
      </v-dialog>

      <!-------------Properties Loader----------------------->
      <loader v-if="isPageLoading" v-bind="loaderProps" class="mt-2" />

      <!----------------------------->
      <!--------No properties-------->
      <!----------------------------->
      <v-sheet
        v-else-if="!properties.length"
        height="60vh"
        class="pa-10 d-flex align-center justify-center flex-column bg-primary"
      >
        <span class="grey--text text--lighten-1 mb-4" style="font-size: 80px">
          <img
            src="@/assets/img/svgs/building.svg"
            alt="No Data Building Image"
            class="grey--text text--lighten-1"
            width="50"
            loading="lazy"
          />
        </span>
        <h3 class="grey--text text--darken-3 my-0">
          لا توجد عقارات مضافة حتى الآن
        </h3>
        <h5 class="grey--text mb-0">
          تظهر كل العقارات المضافة في هذه القائمة مع إمكانية عرض كل المعلومات
          الخاصة
        </h5>
        <h5 class="grey--text mt-0">بها من وحدات و عقود و سندات و وثائق</h5>
      </v-sheet>

      <!----------------------------->
      <!------table view------------->
      <!---------------------------->

      <data-table-property
        v-else-if="!gridView"
        class="mt-2"
        :properties="properties"
        :grid-view="gridView"
        :headers="propertiesTableHeaders"
        @edit-property="editProperty"
        @del-property="deleteConfirm"
      />

      <!----------------------------->
      <!------------grid view-------->
      <!----------------------------->

      <v-row class="mt-2" v-else-if="gridView">
        <v-col
          class="property-col relative set__hover"
          data-test="property-card"
          cols="12"
          :sm="6"
          :md="4"
          :lg="3"
          v-for="property in properties"
          :key="property._id"
        >
          <PropertyCardGrid
            :class="{ border__card: property.checked }"
            usedFor="property"
            :options="property"
            propertyName="العقار"
            @refresh-properties-list="getProperties"
            @edit-property="editProperty"
            @del-property="deleteConfirm"
          >
          </PropertyCardGrid>
          <v-checkbox
            :class="[
              'reset-all ma-0 checkbox checkbox__property',
              { checked: property.checked }
            ]"
            dense
            hide-details
            v-model="property.checked"
            v-if="$hasPermission('realestates', 'realestates', 'delete')"
          />
          <div
            v-if="property.unitsCount"
            class="position-absolute units-count-card d-flex align-center font-14"
          >
            <span>{{ property.unitsCount }}</span>
            <span class="units-count-card-text mr-1">وحدة</span>
          </div>
        </v-col>
      </v-row>

      <!----------pagination--------->

      <div class="text-center mt-6 mb-5" v-if="pagination.pagesCount > 1">
        <Pagination
          :pagination="pagination"
          @onChange="handleGetProperties()"
        />
      </div>
    </v-container>

    <!----------------------------->
    <!----------FastActions-------->
    <!----------------------------->

    <div class="d-flex align-center justify-end ml-3 ml-md-8 fast-actions">
      <v-btn
        v-if="!isCustomer"
        data-test="openModal"
        color="primary"
        large
        class="font-weight-bold fast-actions-btn pa-5"
        @click="openAddDialog"
      >
        <img :src="buildingIcon" class="mx-2" loading="lazy" />
        إضافة عقار
      </v-btn>
      <!-- Fastactions -->
      <fastActions />
    </div>
  </div>
</template>

<style>
.v-icon.v-icon--dense {
  font-size: 25px !important;
}
</style>
<script>
import PropertyCardGrid from '@/components/properties/PropertyCardGrid'
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import multipleSelections from '@/mixins/multipleSelections'
import setView from '@/mixins/setView'
import { realEstateService, MultipleDeletion } from '@/services'
import DataTableProperty from '@/components/properties/DataTableProperty'
import ToolBar from '@/components/listing/ToolBar'
import Pagination from '@/components/helper/pagination.vue'
import RealEstatStat from '@/components/properties/RealEstatStat'
import cardExceedLimit from '@/components/messages/cardExceedLimit'
import PropertyFilter from '@/components/properties/PropertyFilter.vue'
import MultipleSelectionsForDelete from '@/components/helper/MultipleSelectionsForDelete'
import fastActions from '@/components/helper/fastActions'
import loader from '@/components/helper/loader.vue'

export default {
  name: 'Properties',
  components: {
    unitModal: () => import('@/components/add-unit/unitModal'),
    fastActions,
    RealEstatStat,
    DataTableProperty,
    PropertyCardGrid,
    ToolBar,
    Pagination,
    cardExceedLimit,
    PropertyFilter,
    MultipleSelectionsForDelete,
    loader
  },
  mixins: [multipleSelections, setView],
  data() {
    return {
      datesQuery: {
        startDate: '',
        endDate: ''
      },
      availableStatusesFilters: [],
      upload: require('@/assets/img/export-data/upload-grey.svg'),
      exceedTitle:
        'لقد  استنفذت العدد المحدد من الإضافات المتاحة في باقتك الحالية وبإمكانك ترقية الباقة لإجراء إضافات جديدة',
      exceedSubTitle:
        'يمكنك ترقية الباقة  الحالية  لباقة أعلى أو التواصل معنا مباشرة في حال كان اشتراكك الحالي في أعلى باقة متاحة',
      cardsInfoState: true,
      isPageLoading: false,
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 12,
        count: 0
      },
      gridView: true,
      dialogState: false,
      properties: [],
      propertiesCount: 0,
      editPropetyId: 'new',
      breadcrumbs: [{ text: 'قائمة العقارات والوحدات', disabled: true }],
      filters: [],
      filterStatus: '',
      headers: [
        { text: 'تحديد', align: 'center', value: 'checked', sortable: false },
        {
          text: 'رقم العقار',
          align: 'center',
          sortable: false,

          value: 'number'
        },
        {
          text: 'اسم العقار',
          align: 'center',
          sortable: false,
          value: 'propertyName'
        },
        {
          text: 'نوع العقار',
          sortable: false,
          value: 'type',
          align: 'center',
          width: 70
        },
        {
          text: 'المساحه',
          align: 'center',
          width: 70,
          value: 'space'
        },
        { text: 'السعر', value: 'price', align: 'center' },
        { text: 'الحاله', align: 'center', value: 'status', sortable: false },
        {
          text: 'المالك',
          value: 'owner.name',
          align: 'center',
          sortable: false
        },
        {
          text: 'المسؤل',
          value: 'moderators',
          sortable: false,
          align: 'center',
          width: 120
        },
        {
          text: 'المستأجر',
          value: 'tenet.name',
          align: 'center'
        }
      ],
      customerTableHeaders: [
        {
          text: 'اسم العقار',
          align: 'center',
          sortable: false,
          value: 'propertyName',
          for: 'all',
          width: 150
        },
        {
          text: 'نوع العقار',
          sortable: false,
          value: 'type',
          align: 'center',
          width: 100,
          for: 'all'
        },
        {
          text: 'الحاله',
          align: 'center',
          value: 'status',
          sortable: false,
          width: 120,
          for: 'owner'
        },
        {
          text: 'حالة العقد',
          align: 'center',
          value: 'contractStatus',
          sortable: false,
          for: 'renter'
        },

        {
          text: 'المستأجر',
          value: 'tenet.name',
          align: 'center',
          sortable: false,
          for: 'owner'
        },
        {
          text: 'قيمة الإيجار السنوية',
          value: 'netAnnualRent',
          align: 'center',
          sortable: false,
          for: 'all'
        },
        {
          text: 'قيمة الخدمات السنوية',
          value: 'netAnnualUtilities',
          align: 'center',
          sortable: false,
          for: 'all'
        },
        {
          text: 'تاريخ القسط القادم',
          value: 'nextInstallmentDate',
          align: 'center',
          sortable: false,
          for: 'renter'
        },
        {
          text: 'مدير العقار',
          value: 'customerModerators',
          sortable: false,
          align: 'center',
          for: 'all'
        }
      ],
      propertyInfo: {},
      addState: false,
      addCard: {
        unitStr: 'اضافه عقارات أخري',
        title: 'تمت إضافة عقارك بنجاح',
        subTitle: 'الان يمكنك إضافة المزيد من عقاراتك'
      },
      isNewPropertyUnit: false,
      parentId: '',
      addPropCardIcon: require('@/assets/img/svgs/addPropCardIcon.svg'),
      buildingIcon: require('@/assets/img/svgs/buildings.svg'),
      currentEnv: JSON.parse(localStorage.getItem('currentEnv')),
      showHasContractsOnly: false,
      search: '',
      customerRoles: {
        renter: 'tenet',
        owner: 'owner'
      }
    }
  },
  mounted() {
    if (this.addNewProperty === 1) {
      this.openAddDialog()
    }
  },
  created() {
    this.$emit('updateInformativeTip', this.$route.meta?.informativeTip)
    this.handleGetProperties()
  },
  watch: {
    customerDefaultRole() {
      this.handleGetProperties()
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'customerDefaultRole']),
    ...mapGetters('accountState', ['isCustomer']),
    availableFilters() {
      return [
        {
          nameAr: 'الكل',
          nameEn: 'count',
          code: 'count',
          _id: '',
          count: this.propertiesCount
        },
        ...this.availableStatusesFilters
      ]
    },
    propertiesTableHeaders() {
      if (!this.isCustomer) {
        return this.$hasPermission('realestates', 'realestates', 'delete')
          ? this.headers
          : this.headers.filter((header) => header.value !== 'checked')
      }
      return this.customerTableHeaders.filter(
        (header) =>
          header.for === 'all' || header.for === this.customerDefaultRole
      )
    },
    loaderProps() {
      return {
        numberOfLines: 8,
        laoderClasses: 'mx-auto',
        type: 'card'
      }
    },
    hasPermission() {
      return (
        this.$hasPermission('realestates', 'realestates', 'add') &&
        !this.exceededAddingProperty
      )
    },
    exceededAddingProperty() {
      return this.$store.getters['accountState/exceededAddingProperty']
    },
    addNewProperty() {
      return this.$route.params.addNewProperty
    },
    sm() {
      return this.$vuetify.breakpoint.smAndDown
    },
    toolbarProps() {
      return {
        breadcrumbs: this.breadcrumbs,
        length: this.pagination.count,
        cardsInfoState: this.cardsInfoState,
        showDateFilter: !this.isCustomer,
        title: 'العقارات والوحدات '
      }
    },
    /* Multiple selections */
    // ------------------ //
    deleteTitle() {
      return this.showSelectAll ? 'حذف العقارات' : 'حذف العقار'
    },
    restoreTitle() {
      return this.showSelectAll
        ? 'استرجاع العقارات المحذوفة'
        : 'استرجاع العقار المحذوف'
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapActions('accountState', ['getUserBundle']),
    setDate({ dateType, date }) {
      this.cardsInfoState = true
      this.datesQuery[`${dateType}`] = date
    },
    /* Multiple selections */
    // ------------------ //
    // Delete properties with multiple selections
    // eslint-disable-next-line consistent-return
    async deleteItems() {
      const message = this.showSelectAll
        ? `هل انت متاكد من حذف العقارات وعددهم ${this.getAllChecked.length}`
        : 'هل انت متاكد من حذف العقار'

      // 1 - Open popup and confirm delete or not
      const status = await this.$root.$confirm.open({
        title: this.deleteTitle,
        message,
        options: {
          color: 'red'
        }
      })

      // 2 - If confirmed
      if (status) {
        // Check first all checked from the user not include unitCounts
        if (this.getAllChecked.length !== 0) {
          try {
            const body = {
              ids: this.getAllChecked,
              environment: this.currentEnv._id
            }
            await MultipleDeletion.deleteRealestate(body)

            this.addNotification({
              text: 'تم الحذف بنجاح',
              color: 'success'
            })

            // 2 - Delete property from properties
            this.getAllChecked.forEach((id) => {
              const index = this.properties.findIndex(({ _id }) => _id === id)
              this.properties.splice(index, 1)
            })
          } catch (err) {
            const errorMessage = err.response?.data?.message
            const isArabicMessage = /[\u0600-\u06FF]/g.test(errorMessage)
            this.addNotification({
              text: isArabicMessage
                ? errorMessage
                : 'حدث خطأ اثناء حذف العقارات، يرجى المحاوله مجددا',
              color: 'error'
            })
          }
        } else {
          this.addNotification({
            text: 'هذه العقارات تحتوي على وحدات يجب حذف الوحدات اولا حتى يمكنك حذف هذه العقارات',
            color: 'error'
          })
        }
      }
    },
    // Restore properties with multiple selections
    async restoreDelete() {
      const message = this.showSelectAll
        ? `هل انت متاكد من استرجاع العقارات المحذوفة وعددهم ${this.getAllChecked.length}`
        : 'هل انت متاكد من استرجاع العقار المحذوف'
      //
      const status = await this.$root.$confirm.open({
        title: this.restoreTitle,
        statusRestore: true,
        message,
        options: {
          color: 'red'
        }
      })
      console.log(status)
    },
    allDeleteProperties() {
      console.log('All delete properties')
    },
    switched(data) {
      const { name, value } = data
      this.statusRestore = value

      if (name === 'deleted') {
        return this.allDeleteProperties()
      }
      return this.getProperties()
    },
    async getProperties() {
      this.isPageLoading = true
      const filters = {
        statuses: this.filterStatus ? [this.filterStatus] : '',
        types: [...this.filters],
        ...(this.customerDefaultRole === 'owner' && {
          child: false
        }),
        ...(!this.isCustomer && {
          child: false
        }),
        environments: [this.currentEnv?._id]
      }
      if (this.isCustomer) {
        filters.user = this.user._id
        filters.userType = this.customerRoles[this.customerDefaultRole]
      }
      const query = Object.keys(filters).reduce(
        (acc, key) =>
          filters[key].length === 0
            ? { ...acc }
            : {
                ...acc,
                [key]: filters[key]
              },
        {}
      )
      if (this.showHasContractsOnly) {
        query.hasContract = true
      }
      query.parentId = null
      try {
        let _data = `pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`
        this.search && (_data += `&search=${this.search}`)
        const { data } = await realEstateService.getRealEstates(query, _data)
        this.pagination.pagesCount = Math.ceil(data.count / data.pageSize)
        this.properties = data?.realEstates
          ? data?.realEstates?.map((proprty) => ({
              ...proprty,
              checked: false
            }))
          : []

        this.propertiesCount = data.count
        this.pagination.count = data.count

        this.availableStatusesFilters = [...data.countByStatus]
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء المحاوله مره أخرى',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    openAddDialog() {
      if (!this.hasPermission) {
        this.addNotification({
          text: 'ليس لديك صلاحية لإضافة عقارات',
          color: 'error'
        })
        return
      }
      this.editPropetyId = 'new'
      this.dialogState = true
      this.propertyInfo = {
        newUserChecked: false
      }
      this.isNewPropertyUnit = false
    },
    editProperty(val) {
      this.editPropetyId = val.id
      this.propertyInfo = val
      this.dialogState = true
    },
    openAddNewNoti(options) {
      this.hasUnits = options.hasUnits
      this.parentPropertyId = options.id
      this.newUnitAddress = options.address
      this.AddNewNotiDialog = true
    },
    changeFilterTypes(typesFilter) {
      this.filters = typesFilter
      this.pagination.pageNumber = 1
      this.handleGetProperties()
    },
    changeGridView(status) {
      this.updateSetTableView(this.$route.name, status)
      this.gridView = status
      this.handleGetProperties()
    },
    changeTableHeaders(tableHeaders) {
      this.tableHeaders = tableHeaders
    },
    toggleShowStats() {
      this.cardsInfoState = !this.cardsInfoState
    },
    deleteConfirm(id) {
      this.$root.$confirm
        .open({
          title: 'حذف العقار',
          message: 'هل أنت متأكد من حذف العقار، العملية لا يمكن استرجاعها',
          options: {
            color: 'error',
            width: 400,
            zIndex: 200
          }
        })
        .then((confirm) => {
          if (confirm) {
            this.delProperty(id)
          }
        })
    },
    async delProperty(id) {
      realEstateService
        .deleteRealEstate(id)
        .then(async () => {
          this.addNotification({
            text: `تم الحذف بنجاح`,
            color: 'success'
          })
          this.getProperties()
          this.getUserBundle()
        })
        .catch(() => {
          this.addNotification({
            text: `حدث خطاء`,
            color: 'error'
          })
        })
    },
    closeAddDialog(val) {
      if (val === 'unit') {
        if (this.dialogState) {
          this.addCard.unitStr = 'اضافه عقار أخر'
          this.addCard.title = 'تمت إضافة عقارك بنجاح'
          this.addCard.subTitle = 'الان يمكنك إضافة المزيد من عقاراتك'
        } else {
          this.addCard.unitStr = 'تفاصيل الوحده'
          this.addCard.title = 'تم تعديل بيانات وحدتك العقاريه بنجاح'
          this.addCard.subTitle = 'الأم يمكنك تصفح التفاصيل الداخليه للوحده'
        }
        this.addState = true
      }
      this.dialogState = false
    },
    changeFilterStatus(filter) {
      this.filterStatus = filter
      this.pagination.pageNumber = 1
      this.getProperties()
    },
    async setModal() {
      this.addState = false
      await this.getProperties().then(() => {
        setTimeout(() => {
          this.dialogState = true
        }, 300)
      })
    },
    showHasAContract(val) {
      this.showHasContractsOnly = val
      this.pagination.pageNumber = 1
      this.getProperties()
    },
    searchRealEstates(searchText) {
      this.search = searchText
      this.pagination.pageNumber = 1
      this.handleGetProperties()
    },
    async getCustomerRealEstates() {
      this.isPageLoading = true
      const body = {
        environment: this.currentEnv._id,
        user: this.user._id,
        userType: this.customerDefaultRole === 'owner' ? 'owner' : 'tenet',
        pageSize: this.pagination.pageSize,
        pageNumber: this.pagination.pageNumber,
        ...(this.filters.length && {
          types: [...this.filters]
        }),
        ...(this.search && {
          propertyName: this.search
        })
      }
      try {
        const { data } = await realEstateService.getCustomerRealEstates(body)
        this.pagination.count = data.count
        this.pagination.pagesCount = Math.ceil(
          this.pagination.count / data.pageSize
        )
        this.properties = data.realEstates
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء المحاوله مره أخرى',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    handleGetProperties() {
      return this.isCustomer && !this.gridView
        ? this.getCustomerRealEstates()
        : this.getProperties()
    }
  },
  metaInfo() {
    return {
      title: 'قائمة العقارات والوحدات '
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_properties.scss';
@import '@/styles/material-dashboard/fastactions.scss';
@import '@/styles/material-dashboard/_statistics-cards.scss';
</style>
